/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { checkIsActive } from '../../../../_helpers';

export function HeaderMenu({ layoutProps }) {
  const intl = useIntl();
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? 'menu-item-active' : '';
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            '/dashboard'
          )}`}
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">
              {intl.formatMessage({ id: 'MENU.DASHBOARD.SHORT' })}
            </span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            '/student-management'
          )}`}
        >
          <NavLink className="menu-link" to="/student-management">
            <span className="menu-text">
              {intl.formatMessage({ id: 'MENU.STUDENT.SHORT' })}
            </span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            '/diary-management'
          )}`}
        >
          <NavLink className="menu-link" to="/diary-management">
            <span className="menu-text">
              {intl.formatMessage({ id: 'MENU.DIARY.SHORT' })}
            </span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        <li className={`menu-item menu-item-rel ${getMenuItemActive('/news')}`}>
          <NavLink className="menu-link" to="/news">
            <span className="menu-text">
              {intl.formatMessage({ id: 'MENU.NEWS.SHORT' })}
            </span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
