import React from 'react';
import { RecentDiaryDialogSentiment } from './RecentDiaryDialogSentiment';

export function RecentDiaryDialogBodyContent({ content }) {
  return (
    <React.Fragment>
      {/* begin: story */}
      <div className="story">
        <h3>เรื่องราวที่ได้พบเจอ</h3>
        {content.storySentiment && (
          <div className="story__sentiment">
            <RecentDiaryDialogSentiment sentiment={content.storySentiment} />
          </div>
        )}
        <p className="lead">{content.story}</p>
      </div>
      {/* end: story */}

      {/* begin: summarize */}
      <div className="summarize">
        <h3>สื่งที่ได้เรียนรู้ </h3>
        {content.summarizeSentiment && (
          <div className="summarize__sentiment">
            <RecentDiaryDialogSentiment
              sentiment={content.summarizeSentiment}
            />
          </div>
        )}
        <p className="lead">{content.summarize}</p>
      </div>
      {/* end: summarize */}
    </React.Fragment>
  );
}
