import { createSlice } from "@reduxjs/toolkit";

const initialNewsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    news: [],
    newsForRead: undefined,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const newsSlice = createSlice({
    name: "news",
    initialState: initialNewsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        newsFetched: (state, action) => {
            state.actionsLoading = false;
            state.newsForRead = action.payload.newsForRead;
            state.error = null;
        },
        // findCustomers
        allNewsFetched: (state, action) => {
            const { news, page, totalCount } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.news = [...state.news, ...news]
            state.page = page;
            state.totalCount = totalCount;

            // state.entities = entities;
            // state.totalCount = totalCount;
        },
        destroyAllNews: (state) => {
            state.news = []
            state.page = 1;
        }

    },
});
