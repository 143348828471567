import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../../_metronic/layout';
import { logout } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';

class Logout extends Component {
  componentDidMount() {
    const { accessToken, refreshToken } = this.props;
    logout(accessToken, refreshToken).then((res) => {
      this.props.logout();
    });
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? (
      <LayoutSplashScreen />
    ) : (
      <Redirect to="/auth/login" />
    );
  }
}

export default connect(
  ({ auth }) => ({
    hasAuthToken: Boolean(auth.accessToken),
    accessToken: auth.accessToken,
    refreshToken: auth.refreshToken,
  }),
  auth.actions
)(Logout);
