import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import { ModalProgressBar } from '../../../../controls';

export function RecentDiaryDialogHeader({ id }) {
  // Redux
  const { diaryForRead, actionsLoading } = useSelector(
    (state) => ({
      diaryForRead: state.dashboard.diary.diaryForRead,
      actionsLoading: state.dashboard.diary.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState('');

  // Title
  useEffect(() => {
    let _title = id ? '' : 'หัวข้อบันทึกการเรียนรู้';
    if (diaryForRead && id) {
      _title = `หัวข้อ: ${diaryForRead.title}`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [diaryForRead, actionsLoading]);

  return (
    <React.Fragment>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </React.Fragment>
  );
}
