import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export function SentimentColumnFormatter(cellContent, row, rowIndex) {
  const sentimentToText = (sentiment) => {
    switch (sentiment.polarity) {
      case 'positive':
        return <i class="fas fa-chevron-circle-up text-success"></i>;
      case 'negative':
        return <i class="fas fa-chevron-circle-down text-danger"></i>;
      default:
        return <i class="fas fa-minus-circle"></i>;
    }
  };

  const renderStoryTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      เรื่องราวที่ได้พบเจอ
    </Tooltip>
  );

  const renderSumTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      สื่งที่ได้เรียนรู้
    </Tooltip>
  );

  return (
    <React.Fragment>
      {row.storySentiment && (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderStoryTooltip}
        >
          {sentimentToText(row.storySentiment)}
        </OverlayTrigger>
      )}{' '}
      {row.summarizeSentiment && (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderSumTooltip}
        >
          {sentimentToText(row.summarizeSentiment)}
        </OverlayTrigger>
      )}
    </React.Fragment>
  );
}
