import React, { useMemo } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../../../app/pages/_redux/dashboard/dashboardActions';
import { RecentDiaryDialogHeader } from './RecentDiaryDialogHeader';
import { RecentDiaryDialogBody } from './RecentDiaryDialogBody';
import { useDashboardUIContext } from '../../../../../../app/pages/context/DashboardUIContext';

export function RecentDiaryDialog({ id, show, onHide }) {
  // Context
  const dashboardUIContext = useDashboardUIContext();
  const dashboardUIProps = useMemo(() => {
    return {
      initDiary: dashboardUIContext.initDiary,
      queryParams: dashboardUIContext.queryParams,
    };
  }, [dashboardUIContext]);
  // Redux
  const dispatch = useDispatch();
  const { actionsLoading, diaryForRead, reactions } = useSelector(
    (state) => ({
      actionsLoading: state.dashboard.diary.actionsLoading,
      diaryForRead: state.dashboard.diary.diaryForRead,
      reactions: state.dashboard.diary.reactions,
    }),
    shallowEqual
  );

  // Get Diary
  useEffect(() => {
    // call current diary from server
    dispatch(actions.fetchDiaryById(id));
  }, [id, dispatch]);

  // Get Reactions
  useEffect(() => {
    dispatch(actions.fetchReactions());
  }, [dispatch]);

  const saveReaction = (reaction) => {
    dispatch(actions.updateReaction(diaryForRead, reaction));
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <RecentDiaryDialogHeader id={id} />
      <RecentDiaryDialogBody
        saveReaction={saveReaction}
        diary={diaryForRead || dashboardUIProps.initDiary}
        reactions={reactions}
        actionsLoading={actionsLoading}
        onHide={onHide}
      />
    </Modal>
  );
}
