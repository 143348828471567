import * as requestFromServer from './dashboardCrud';
import { dashboardSlice, callTypes, module } from './dashboardSlice';
import { toast } from 'react-toastify';

const { actions } = dashboardSlice;

// GET:: Recent Diary
export const fetchDiary = (queryParams) => (dispatch) => {
  dispatch(
    actions.startCall({ callType: callTypes.list, module: module.diary })
  );
  return requestFromServer
    .getRecentDiary(queryParams)
    .then((response) => {
      const entities = response.data.data;
      const totalCount = response.data.totalCount;

      dispatch(actions.diariesFetched({ entities, totalCount }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find diary";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          module: module.diary,
        })
      );
    });
};

// GET:: One Diary
export const fetchDiaryById = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.diaryFetched({ diaryForRead: undefined }));
  }

  dispatch(
    actions.startCall({ callType: callTypes.action, module: module.diary })
  );
  return requestFromServer
    .getDiaryById(id)
    .then((response) => {
      dispatch(actions.diaryFetched({ diaryForRead: response.data.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find diary";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          module: module.diary,
        })
      );
    });
};

// GET:: Reactions
export const fetchReactions = () => (dispatch) => {
  dispatch(
    actions.startCall({ callType: callTypes.action, module: module.diary })
  );

  return requestFromServer
    .getReactions()
    .then((response) => {
      dispatch(actions.reactionsFetched({ reactions: response.data.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find reaction";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          module: module.diary,
        })
      );
    });
};

// PUT: Update Reaction
export const updateReaction = (diary, reaction) => (dispatch) => {
  dispatch(
    actions.startCall({ callType: callTypes.action, module: module.diary })
  );

  let status = {
    isRead: true,
    reaction: reaction._id,
  };

  return requestFromServer
    .updateReaction(diary, status)
    .then((response) => {
      dispatch(actions.reactionUpdated(response.data.data));

      toast.success(response.data.message);
    })
    .catch((error) => {
      toast.error('เกิดข้อผิดพลากกรุณาลองใหม่');

      error.clientMessage = "Can't update diary";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          module: module.diary,
        })
      );
    });
};
