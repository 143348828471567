import React from 'react';
import { AccountSetting } from './account-setting/AccountSetting';
import { ChangePassword } from './change-password/ChangePassword';

export function Account() {
  return (
    <>
      <AccountSetting />
      <ChangePassword />
    </>
  );
}
