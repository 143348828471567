import React from 'react';
import { Button } from 'react-bootstrap';

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openRecentDiaryDialog, messages }
) {
  return (
    <React.Fragment>
      <Button
        variant="success"
        onClick={() => openRecentDiaryDialog(row._id)}
        block
      >
        <i className="fas fa-book-reader"></i>
        {messages.read}
      </Button>
    </React.Fragment>
  );
}
