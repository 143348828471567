import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from '../../../../../../_metronic/_helpers';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { useIntl } from 'react-intl';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as columnFormatters from './column-formatter';
import { useDashboardUIContext } from '../../../../../../app/pages/context/DashboardUIContext';
import { Pagination } from '../../../../controls';
import * as uiHelpers from '../../../../../../app/pages/context/DashboardUIHelper';
import * as actions from '../../../../../../app/pages/_redux/dashboard/dashboardActions';

export function RecentDiaryTable() {
  // Context
  const dashboardUIContext = useDashboardUIContext();
  const dashboardUIProps = useMemo(() => {
    return {
      queryParams: dashboardUIContext.queryParams,
      setQueryParams: dashboardUIContext.setQueryParams,
      openRecentDiaryDialog: dashboardUIContext.openRecentDiaryDialog,
    };
  }, [dashboardUIContext]);
  // Intl
  const intl = useIntl();
  dayjs.extend(relativeTime);
  // Redux
  const { currentState } = useSelector(
    (state) => ({ currentState: state.dashboard.diary }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // UseEffect
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchDiary(dashboardUIProps.queryParams));
  }, [dashboardUIProps.queryParams, dispatch]);

  // Table
  //   Column
  const columns = [
    {
      dataField: '_id',
      text: intl.formatMessage({
        id: 'DASHBOARD.WIDGET.RECENT_DIARY.TABLE.NAME',
      }),
      formatter: (cell, row) => `${row.owner.firstName} ${row.owner.lastName}`,
    },
    {
      dataField: 'owner.degreeProgram.name',
      text: intl.formatMessage({
        id: 'DASHBOARD.WIDGET.RECENT_DIARY.TABLE.DEGREEPROGRAM',
      }),
    },
    {
      dataField: 'storySentiments',
      text: intl.formatMessage({
        id: 'DASHBOARD.WIDGET.RECENT_DIARY.TABLE.SENTIMENT',
      }),
      formatter: columnFormatters.SentimentColumnFormatter,
    },
    {
      dataField: 'createdAt',
      text: intl.formatMessage({
        id: 'DASHBOARD.WIDGET.RECENT_DIARY.TABLE.CREATEDAT',
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => dayjs(row.createdAt).format('DD/MM/YYYY'),
    },
    {
      dataField: 'updatedAt',
      text: intl.formatMessage({
        id: 'DASHBOARD.WIDGET.RECENT_DIARY.TABLE.UPDATEDAT',
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) =>
        dayjs(row.createdAt)
          .locale('th')
          .fromNow(),
    },
    {
      dataField: 'action',
      text: intl.formatMessage({
        id: 'DASHBOARD.WIDGET.RECENT_DIARY.TABLE.ACTION',
      }),
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openRecentDiaryDialog: dashboardUIProps.openRecentDiaryDialog,
        messages: {
          read: intl.formatMessage({
            id: 'DASHBOARD.WIDGET.RECENT_DIARY.TABLE.FORMATTER.READ',
          }),
        },
      },
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-3">
      {`${intl.formatMessage({
        id: 'DASHBOARD.WIDGET.RECENT_DIARY.TABLE.PAGINATION.SHOW',
      })} ${from} ${intl.formatMessage({
        id: 'DASHBOARD.WIDGET.RECENT_DIARY.TABLE.PAGINATION.TO',
      })} ${to} ${intl.formatMessage({
        id: 'DASHBOARD.WIDGET.RECENT_DIARY.TABLE.PAGINATION.OF',
      })} ${size} ${intl.formatMessage({
        id: 'DASHBOARD.WIDGET.RECENT_DIARY.TABLE.PAGINATION.RESULTS',
      })}`}
    </span>
  );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: dashboardUIProps.queryParams.pageSize,
    page: dashboardUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Pagination isLoading={listLoading} paginationProps={paginationProps}>
            <BootstrapTable
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-head-bg table-vertical-center"
              bootstrap4
              bordered={false}
              remote
              keyField="_id"
              data={entities || []}
              columns={columns}
              defaultSorted={uiHelpers.defaultSorted}
              onTableChange={getHandlerTableChange(
                dashboardUIProps.setQueryParams
              )}
              {...paginationTableProps}
            >
              <PleaseWaitMessage entities={entities} />
              <NoRecordsFoundMessage entities={entities} />
            </BootstrapTable>
          </Pagination>
        );
      }}
    </PaginationProvider>
  );
}
