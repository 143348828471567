/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { checkIsActive } from '../../../../_helpers';
import { useIntl } from 'react-intl';

export function AsideMenuList({ layoutProps }) {
  const intl = useIntl();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
      : '';
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Profile*/}
        <li
          className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className=" menu-icon">
              <i className="menu-icon icon-lg fa fa-home"></i>
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: 'ASIDE_MENU.DASHBOARD' })}
            </span>
          </NavLink>
        </li>
        {/*end::1 Profile*/}

        {/*begin::1 News*/}
        <li
          className={`menu-item ${getMenuItemActive('/news', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/news">
            <span className=" menu-icon">
              <i className="menu-icon icon-lg far fa-newspaper"></i>
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: 'ASIDE_MENU.NEWS' })}
            </span>
          </NavLink>
        </li>
        {/*end::1 News*/}

        {/* Components */}
        {/* begin::Management menu */}

        <li className="menu-section ">
          <h4 className="menu-text">
            {intl.formatMessage({ id: 'ASIDE_MENU.TITLE.MANAGEMENT' })}
          </h4>
          <i className="menu-icon icon-lg flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* Material-UI */}
        {/*begin::1 Student Management*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/student-management',
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/student-management">
            <span className="menu-icon">
              <i className="menu-icon icon-lg fas fa-users-cog"></i>
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: 'ASIDE_MENU.STUDENT_MANAGEMENT' })}
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Diary Management*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/diary-management',
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/diary-management">
            <span className="menu-icon">
              <i className="menu-icon icon-lg text-dark-20 flaticon2-writing"></i>
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: 'ASIDE_MENU.DIARY_MANAGEMENT' })}
            </span>
            {/* <i className="menu-arrow" /> */}
          </NavLink>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
