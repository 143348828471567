import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../controls';

export const ChangePassword = (props) => {
  const intl = useIntl();

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  };

  const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(
        8,
        intl.formatMessage({
          id: 'ACCOUNT.CHANGEPASSWORD.VALIDATION.MIN',
        })
      )
      .max(
        32,
        intl.formatMessage({
          id: 'ACCOUNT.CHANGEPASSWORD.VALIDATION.MAX',
        })
      )
      .required(
        intl.formatMessage({
          id: 'ACCOUNT.CHANGEPASSWORD.VALIDATION.REQUIRED_FIELD',
        })
      ),
    newPassword: Yup.string()
      .min(
        8,
        intl.formatMessage({
          id: 'ACCOUNT.CHANGEPASSWORD.VALIDATION.MIN',
        })
      )
      .max(
        32,
        intl.formatMessage({
          id: 'ACCOUNT.CHANGEPASSWORD.VALIDATION.MAX',
        })
      )
      .required(
        intl.formatMessage({
          id: 'ACCOUNT.CHANGEPASSWORD.VALIDATION.REQUIRED_FIELD',
        })
      ),
    newPasswordConfirm: Yup.string()
      .oneOf(
        [Yup.ref('newPassword'), null],
        intl.formatMessage({ id: 'ACCOUNT.CHANGEPASSWORD.VALIDATION.MATCH' })
      )
      .min(
        8,
        intl.formatMessage({
          id: 'ACCOUNT.CHANGEPASSWORD.VALIDATION.MIN',
        })
      )
      .max(
        32,
        intl.formatMessage({
          id: 'ACCOUNT.CHANGEPASSWORD.VALIDATION.MAX',
        })
      )
      .required(
        intl.formatMessage({
          id: 'ACCOUNT.CHANGEPASSWORD.VALIDATION.REQUIRED_FIELD',
        })
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log('values', values);
      setTimeout(() => {
        setSubmitting(false);
      }, 2000);
    },
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage(
          { id: 'ACCOUNT.CHANGEPASSWORD.TITLE' },
          props.title
        )}
      >
        <CardHeaderToolbar>
          <Button
            variant="primary"
            disabled={formik.isSubmitting}
            type="submit"
            onClick={formik.handleSubmit}
          >
            {formik.isSubmitting
              ? intl.formatMessage({ id: 'ACCOUNT.CHANGEPASSWORD.SUBMITTING' })
              : intl.formatMessage({ id: 'ACCOUNT.CHANGEPASSWORD.SUBMIT' })}
          </Button>
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody>
        {/* Change Password Begin */}
        <Form>
          {/* Old Password */}
          <Form.Group as={Row} controlId="formOldPassword">
            <Form.Label column sm={2} className="text-lg-right text-left">
              {intl.formatMessage(
                { id: 'ACCOUNT.CHANGEPASSWORD.FORM_OLD_PASSWORD' },
                props.form_old_password
              )}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="password"
                placeholder={intl.formatMessage(
                  { id: 'ACCOUNT.CHANGEPASSWORD.FORM_OLD_PASSWORD' },
                  props.form_old_password
                )}
                className={getInputClasses('oldPassword')}
                {...formik.getFieldProps('oldPassword')}
                disabled
              />
              {formik.touched.oldPassword && formik.errors.oldPassword && (
                <div className="text-danger">{formik.errors.oldPassword}</div>
              )}
            </Col>
          </Form.Group>
          {/* New Password */}
          <Form.Group as={Row} controlId="formNewPassword">
            <Form.Label column sm={2} className="text-lg-right text-left">
              {intl.formatMessage(
                { id: 'ACCOUNT.CHANGEPASSWORD.FORM_NEW_PASSWORD' },
                props.form_new_password
              )}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="password"
                placeholder={intl.formatMessage(
                  { id: 'ACCOUNT.CHANGEPASSWORD.FORM_NEW_PASSWORD' },
                  props.form_new_password
                )}
                className={getInputClasses('newPassword')}
                {...formik.getFieldProps('newPassword')}
                disabled
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className="text-danger">{formik.errors.newPassword}</div>
              )}
            </Col>
          </Form.Group>
          {/* New Password Confirm */}
          <Form.Group as={Row} controlId="formNewPasswordConfirm">
            <Form.Label column sm={2} className="text-lg-right text-left">
              {intl.formatMessage(
                { id: 'ACCOUNT.CHANGEPASSWORD.FORM_NEW_PASSWORD_CONFIRM' },
                props.form_new_password_confirm
              )}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="password"
                placeholder={intl.formatMessage(
                  { id: 'ACCOUNT.CHANGEPASSWORD.FORM_NEW_PASSWORD_CONFIRM' },
                  props.form_new_password_confirm
                )}
                className={getInputClasses('newPasswordConfirm')}
                {...formik.getFieldProps('newPasswordConfirm')}
                disabled
              />
              {formik.touched.newPasswordConfirm &&
                formik.errors.newPasswordConfirm && (
                  <div className="text-danger">
                    {formik.errors.newPasswordConfirm}
                  </div>
                )}
            </Col>
          </Form.Group>
        </Form>
        {/* Change Password End */}
      </CardBody>
    </Card>
  );
};
