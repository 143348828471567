import React from 'react';
import { useSubheader } from '../../_metronic/layout';
import { useIntl } from 'react-intl';
import { Account } from '../../_metronic/_partials/account/Account';

export function AccountPage({ title }) {
  const intl = useIntl();

  const subheader = useSubheader();
  subheader.setTitle(
    intl.formatMessage({ id: 'ACCOUNT.SUBHEADER.TITLE' }, { title })
  );

  return <Account />;
}
