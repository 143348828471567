/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { RecentDiaryTable } from './recent-diary-table/RecentDiaryTable';

export function RecentDiaryTableWidget({ className }) {
  // Intl
  const intl = useIntl();

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            {intl.formatMessage({ id: 'DASHBOARD.WIDGET.RECENT_DIARY.TITLE' })}
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            {intl.formatMessage({
              id: 'DASHBOARD.WIDGET.RECENT_DIARY.SUBTITLE',
            })}
          </span>
        </h3>
        <div className="card-toolbar">
          <Link
            to="/diary-management"
            className="btn btn-primary font-weight-bolder font-size-sm"
          >
            {intl.formatMessage({
              id: 'DASHBOARD.WIDGET.RECENT_DIARY.SEE_MORE',
            })}
          </Link>
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <RecentDiaryTable />
      </div>
    </div>
  );
}
