import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { RecentDiaryDialogBodyContent } from './RecentDiaryDialogBodyContent';
import RecentDiaryDialogBodyReaction from './RecentDiaryDialogBodyReaction';
import { RecentDiaryDialogBodyUser } from './RecentDiaryDialogBodyUser';
import { useIntl } from 'react-intl';

export function RecentDiaryDialogBody({
  saveReaction,
  diary,
  actionsLoading,
  onHide,
  reactions,
}) {
  const intl = useIntl();

  return (
    <React.Fragment>
      <Modal.Body className="overlay overlay-block cursor-default">
        {/* begin:: actionsLoading */}
        {actionsLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}
        {/* end:: actionsLoading */}
        {/* begin:: user */}
        <RecentDiaryDialogBodyUser
          user={diary.owner}
          createdAt={diary.createdAt}
          updatedAt={diary.updatedAt}
        />
        {/* end:: user */}
        {/* begin:: content */}
        <RecentDiaryDialogBodyContent content={diary} />
        {/* end:: content */}
        <div className="separator separator-dashed mb-3"></div>
        {/* begin:: reaction */}
        <RecentDiaryDialogBodyReaction
          reactions={reactions}
          saveReaction={saveReaction}
          status={diary.status}
        />
        {/* end:: reaction */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {intl.formatMessage({ id: 'BUTTON.CLOSE' })}
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
}
