import axios from 'axios';

export const LOGIN_URL = 'auth/signin';
export const REGISTER_URL = 'auth/register';
export const LOGOUT_URL = 'auth/signout';
export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';

export const ME_URL = 'auth/me';

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(
  title,
  email,
  firstName,
  lastName,
  gender,
  personalId,
  confirmPassword,
  degreeProgramId,
  password,
  role
) {
  return axios.post(REGISTER_URL, {
    title,
    email,
    firstName,
    lastName,
    gender,
    personalId,
    confirmPassword,
    degreeProgramId,
    password,
    role,
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function logout(accessToken, refreshToken) {
  return axios.post(LOGOUT_URL, { accessToken, refreshToken });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
