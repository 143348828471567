export const defaultSorted = [{ dataField: 'createdAt', order: 'desc' }];
export const sizePerPageList = [
  { text: '10', value: 10 },
  { text: '20', value: 20 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
];
export const initialFilter = {
  filter: { 'status.isRead': false },
  sortOrder: 'desc', // asc||desc
  sortField: 'createdAt',
  pageNumber: 1,
  pageSize: 10,
};
