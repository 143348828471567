import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { register } from '../_redux/authCrud';
import { Form, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

function Registration(props) {
  const history = useHistory();
  const initialValues = {
    title: '',
    firstName: '',
    lastName: '',
    gender: '',
    personalId: '',
    email: '',
    password: '',
    confirmPassword: '',
    degreeProgramId: '',
    role: 'teacher',
    acceptTerms: false,
  };

  const [degreeProgramId, setDegreeProgram] = useState([]);
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    axios.get('/degreeprogram').then(({ data: { data } }) => {
      setDegreeProgram([...data]);
    });
  }, []);

  const RegistrationSchema = Yup.object().shape({
    title: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    firstName: Yup.string()
      .trim()
      .matches(/^[ก-๏\s]{1,}$/, {
        message: <FormattedMessage id="AUTH.VALIDATION.THAI_REQUIRED" />,
      })
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    lastName: Yup.string()
      .trim()
      .matches(/^[ก-๏\s]{1,}$/, {
        message: <FormattedMessage id="AUTH.VALIDATION.THAI_REQUIRED" />,
      })
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    gender: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    personalId: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    degreeProgramId: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'AUTH.VALIDATION.EMAIL_FORMAT' }))
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    password: Yup.string()
      .min(8, <FormattedMessage id="AUTH.VALIDATION.MIN_LENGTH_FIELD" />)
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,32}$/,
        intl.formatMessage({
          id: 'AUTH.VALIDATION.PASSWORD_WRONG',
        })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          intl.formatMessage({ id: 'AUTH.VALIDATION.PASSWORD_MATCH' })
        ),
      }),
    acceptTerms: Yup.bool().required(
      'You must accept the terms and conditions'
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();

      register(
        values.title,
        values.email,
        values.firstName,
        values.lastName,
        values.gender,
        values.personalId,
        values.confirmPassword,
        values.degreeProgramId,
        values.password,
        values.role
      )
        .then((response) => {
          // props.register(accessToken);
          disableLoading();
          setSubmitting(false);

          if (response.status === 201) {
            Swal.fire({
              title: response.data.message,
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'ตกลง',
            }).then((result) => {
              if (result.isConfirmed) {
                history.push('/auth/login');
              }
            });
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setStatus(error.response.data.message);
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: 'block' }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.REGISTER.DESC" />
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.PH_EMAIL',
            })}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'email'
            )}`}
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.PH_PASSWORD',
            })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'password'
            )}`}
            name="password"
            {...formik.getFieldProps('password')}
          />

          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.PH_CON_PASSWORD',
            })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'confirmPassword'
            )}`}
            name="confirmPassword"
            {...formik.getFieldProps('confirmPassword')}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.confirmPassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Title */}
        <div className="form-group fv-plugins-icon-container">
          <select
            name="title"
            {...formik.getFieldProps('title')}
            className="form-control form-control-solid h-auto py-5 px-6"
          >
            <option value="" disabled>
              {intl.formatMessage({ id: 'AUTH.REGISTER.TITLE.NAME' })}
            </option>
            <option value="อ.">
              {intl.formatMessage({ id: 'AUTH.REGISTER.TITLE.LECTURER' })}
            </option>
            <option value="ดร.">
              {intl.formatMessage({ id: 'AUTH.REGISTER.TITLE.DR' })}
            </option>
            <option value="ผศ.">
              {intl.formatMessage({ id: 'AUTH.REGISTER.TITLE.ASST_PROF' })}
            </option>
            <option value="ผศ. ดร.">
              {intl.formatMessage({ id: 'AUTH.REGISTER.TITLE.ASST_PROF_DR' })}
            </option>
            <option value="รศ.">
              {intl.formatMessage({ id: 'AUTH.REGISTER.TITLE.ASSOC_PROF' })}
            </option>
            <option value="รศ. ดร.">
              {intl.formatMessage({ id: 'AUTH.REGISTER.TITLE.ASSOC_PROF_DR' })}
            </option>
            <option value="ศ.">
              {intl.formatMessage({ id: 'AUTH.REGISTER.TITLE.PROF' })}
            </option>
            <option value="ศ. ดร.">
              {intl.formatMessage({ id: 'AUTH.REGISTER.TITLE.PROF_DR' })}
            </option>
          </select>
          {formik.touched.title && formik.errors.title ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.title}</div>
            </div>
          ) : null}
        </div>
        {/* end: Title */}

        {/* begin: First name */}
        <Form.Row>
          <Form.Group as={Col} md="6">
            <input
              placeholder={intl.formatMessage({
                id: 'AUTH.REGISTER.PH_FIRSTNAME',
              })}
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                'firstName'
              )}`}
              name="firstName"
              {...formik.getFieldProps('firstName')}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.firstName}</div>
              </div>
            ) : null}
          </Form.Group>
          {/* end: First name */}
          {/* begin: Last name */}
          <Form.Group as={Col} md="6">
            <input
              placeholder={intl.formatMessage({
                id: 'AUTH.REGISTER.PH_LASTNAME',
              })}
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                'lastName'
              )}`}
              name="lastName"
              {...formik.getFieldProps('lastName')}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.lastName}</div>
              </div>
            ) : null}
          </Form.Group>
          {/* end: Last name */}

          {/* begin: gender */}
        </Form.Row>
        <div className="form-group fv-plugins-icon-container">
          <select
            name="gender"
            {...formik.getFieldProps('gender')}
            className="form-control form-control-solid h-auto py-5 px-6"
          >
            <option value="" disabled>
              {intl.formatMessage({ id: 'AUTH.REGISTER.GENDER' })}
            </option>
            <option value="ชาย">
              {intl.formatMessage({ id: 'AUTH.REGISTER.GENDER_MALE' })}
            </option>
            <option value="หญิง">
              {intl.formatMessage({ id: 'AUTH.REGISTER.GENDER_FEMALE' })}
            </option>
            <option value="ไม่ระบุ">
              {intl.formatMessage({ id: 'AUTH.REGISTER.GENDER_NONE' })}
            </option>
          </select>
          {formik.touched.gender && formik.errors.gender ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.gender}</div>
            </div>
          ) : null}
        </div>

        {/* end: gender */}

        {/* begin: personalId */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.PH_PERSONAL_ID',
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'personalId'
            )}`}
            name="personalId"
            {...formik.getFieldProps('personalId')}
          />
          {formik.touched.personalId && formik.errors.personalId ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.personalId}</div>
            </div>
          ) : null}
        </div>
        {/* end: personalId */}

        {/* Degree Program */}

        <div className="form-group fv-plugins-icon-container">
          <select
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'degreeProgramId'
            )}`}
            {...formik.getFieldProps('degreeProgramId')}
          >
            <option value="" disabled>
              {intl.formatMessage(
                { id: 'ACCOUNT.SETTING.FORM_DEGREE_PROGRAM' },
                props.form_degree_program
              )}
            </option>
            {degreeProgramId.map((dps) => (
              <option key={dps._id} value={dps._id}>
                {dps.name} {dps.major}
              </option>
            ))}
          </select>
          {formik.touched.degreeProgramId && formik.errors.degreeProgramId && (
            <div className="text-danger">{formik.errors.degreeProgramId}</div>
          )}
        </div>

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps('acceptTerms')}
            />
            <span className="mr-2" />
            <Link
              to="/terms"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="ACCOUNT.SETTING.FORM_TERM" />
            </Link>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>
              <FormattedMessage id="AUTH.REGISTER.SUBMIT_BUTTON" />
            </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              <FormattedMessage id="AUTH.REGISTER.CANCEL_BUTTON" />
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
