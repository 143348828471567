import React from 'react';
import * as dayjs from 'dayjs';
import { URL } from '../../../../../../config/configuration';

export function RecentDiaryDialogBodyUser({ user, createdAt, updatedAt }) {
  return (
    <div className="d-flex align-items-center mb-5 border border-secondary py-5 px-4 rounded">
      {/* begin::Symbol */}
      <div className="symbol symbol-50 symbol-light-success mr-5">
        <div
          className="symbol-label"
          style={{
            backgroundImage: `url(${URL.imagePrefix}/${user.avatar})`,
          }}
        ></div>
      </div>
      {/* end::Symbol */}
      {/* begin::Text */}
      <div className="d-flex flex-column flex-grow-1">
        <h5>{`${user.title}${user.firstName} ${user.lastName}`}</h5>
        <span className="text-muted font-weight-bold">
          {`วันที่สร้าง ${dayjs(createdAt).format('DD/MM/YYYY')}`}
          {createdAt !== updatedAt &&
            ` • แก้ไขเมื่อ ${dayjs(createdAt)
              .locale('th')
              .fromNow()}`}
        </span>
      </div>
    </div>
  );
}
