import axios from 'axios';

export const DIARY_URL = 'teacher/student/diary';
export const TEACHER_URL = 'teacher';
export const REACTION_URL = 'reaction';

// GET => Get Recent Diary
export function getRecentDiary(queryParams) {
  return axios.get(`${TEACHER_URL}/student/diary`, { params: queryParams });
}

// GET => One Diary
export function getDiaryById(id) {
  return axios.get(`${TEACHER_URL}/student/diary/` + id);
}

// GET => Get Reactions
export function getReactions() {
  return axios.get(`${REACTION_URL}`);
}

// PUT => Update Diary Reaction
export function updateReaction(diary, body) {
  return axios.put(`${DIARY_URL}/${diary._id}/read`, body);
}
