import React from 'react';
import { Button } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

export default function RecentDiaryDialogBodyReaction({
  reactions,
  saveReaction,
  status,
}) {
  return (
    <div className="row">
      {reactions.map((reaction) => (
        <Col md={4} key={reaction._id}>
          <Button
            variant="outline-primary"
            block
            onClick={() => saveReaction(reaction)}
            active={status.reaction === reaction._id}
          >
            {reaction.icon} {reaction.name}
          </Button>
        </Col>
      ))}
    </div>
  );
}
