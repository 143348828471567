/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import '../widgets_style.scss';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { DashboardNewsDialog } from './DashboardNewsDialog';
import { Link } from 'react-router-dom';
import { Alert, Badge } from 'react-bootstrap';
import * as dayjs from 'dayjs';

export function DashboardNews({ className }) {
  const intl = useIntl();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);

  // initial fetch
  useEffect(() => {
    setLoading(true);
    axios
      .get(`/news/recent/5`)
      .then((response) => {
        const news = response.data.data;
        setNews(news);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header align-items-center border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            {intl.formatMessage({ id: 'DASHBOARD.WIDGET.NEWS.TITLE' })}
          </h3>
          <div className="card-toolbar">
            <Link to="/news">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm py-2"
              >
                {intl.formatMessage({ id: 'WIDGET.LIST_9.NEWS_BUTTON' })}
              </button>
            </Link>
          </div>
        </div>

        {/* Body */}
        <div className="card-body pt-2">
          {!loading ? (
            news && news.length ? (
              news.map((data) => (
                <div className="news mb-2" key={data._id}>
                  <Badge className="mr-2" variant="light">
                    {dayjs(data.createdAt)
                      .locale('th')
                      .fromNow()}
                  </Badge>
                  <a
                    className="text-dark-75 text-hover-primary font-size-lg"
                    onClick={() => {
                      setShow(!show);
                      setCurrentNews(data);
                    }}
                    key={data._id}
                  >
                    {data.title}
                  </a>
                </div>
              ))
            ) : (
              <div className="card-body pt-2">
                <Alert variant="danger">
                  {intl.formatMessage({
                    id: 'DASHBOARD.WIDGET.NEWS.ALERT.NO_NEWS',
                  })}
                </Alert>
              </div>
            )
          ) : (
            <p>Loading ... </p>
          )}
        </div>

        <DashboardNewsDialog
          show={show}
          news={currentNews || null}
          onHide={() => {
            setShow(false);
            setCurrentNews(null);
          }}
        />
      </div>
    </>
  );
}
