import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';
import { studentSlice } from '../app/modules/StudentManagement/_redux/studentSlice';
import { diarySlice } from '../app/modules/Diary/_redux/diary/dairySlice';
import { newsSlice } from '../app/modules/News/_redux/newsSlice';
import { dashboardSlice } from '../app/pages/_redux/dashboard/dashboardSlice';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  dashboard: dashboardSlice.reducer,
  students: studentSlice.reducer,
  diaries: diarySlice.reducer,
  allNews: newsSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
