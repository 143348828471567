import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import { DashboardPage } from './pages/DashboardPage';
import { AccountPage } from './pages/AccountPage';

const DiariesManagePage = lazy(() =>
  import('./modules/Diary/pages/DiariesManagePage')
);
const StudentManagementPage = lazy(() =>
  import('./modules/StudentManagement/StudentManagementPage')
);
const NewsPage = lazy(() => import('./modules/News/pages/NewsPage'));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/account" component={AccountPage} />
        <Route path="/student-management" component={StudentManagementPage} />
        <Route path="/diary-management" component={DiariesManagePage} />
        <Route path="/news" component={NewsPage} />
        <Redirect to="error/error-v5" />
      </Switch>
    </Suspense>
  );
}
