import React from 'react';
import { ToastContainer } from 'react-toastify';
import {
  DashboardNews,
  RecentDiaryTableWidget,
} from '../../_metronic/_partials/widgets';
import { DashboardUIProvider } from './context/DashboardUIContext';
import { Route } from 'react-router-dom';
import { RecentDiaryDialog } from '../../_metronic/_partials/widgets/advance-tables/recent-diary-widget/recent-diary-dialog/RecentDiaryDialog';

export function DashboardPage({ history }) {
  // UI Event
  const dashboardUIEvents = {
    openRecentDiaryDialog: (id) => {
      history.push(`/dashboard/diary/${id}`);
    },
  };

  return (
    <DashboardUIProvider dashboardUIEvents={dashboardUIEvents}>
      {/* begin:: Route */}
      <Route path="/dashboard/diary/:id">
        {({ history, match }) => (
          <RecentDiaryDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push('/dashboard');
            }}
          ></RecentDiaryDialog>
        )}
      </Route>
      {/* end:: Route */}
      <div className="row">
        <div className="col-sm-12 col-md-8">
          {/* Recent Diary Table Widget */}
          <RecentDiaryTableWidget className="card-stretch gutter-b" />
        </div>
        <div className="col-sm-12 col-md-4">
          {/* News Widget */}
          <DashboardNews className="card-stretch gutter-b" />
        </div>
      </div>
      <ToastContainer />
    </DashboardUIProvider>
  );
}
