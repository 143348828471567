import React from 'react';
import { Modal, Spinner, Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useIntl } from 'react-intl';

export function DashboardNewsDialog({ news, show, onHide }) {
  const intl = useIntl();
  const convertDateToTh = (date) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    return dayjs
      .tz(date, 'Asia/Bangkok')
      .locale('th')
      .format('DD/MM/YYYY');
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {news ? news.title : 'ข่าวประกาศ'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        {news ? (
          <div>
            <div className="row">
              <div className="col mb-2">
                <img
                  alt="news_img"
                  src={news.urlToImage}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p>{news.content}</p>
              </div>
            </div>

            <div className="row">
              <div className="col ">
                <p className="text-muted mt-2 ml-2">
                  {`${intl.formatMessage({
                    id: 'DASHBOARD.WIDGET.NEWS.CREATED_AT',
                  })} `}
                  {convertDateToTh(news.createdAt)}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {intl.formatMessage({ id: 'BUTTON.CLOSE' })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
