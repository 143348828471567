import React from 'react';

export function RecentDiaryDialogSentiment({ sentiment }) {
  const sentimentToText = (sentiment) => {
    switch (sentiment.polarity) {
      case 'positive':
        return (
          <span className="badge rounded-pill bg-light-success text-success p-2">
            เชิงบวก{' '}
            <i className="fas fa-chevron-circle-up text-success fa-sm"></i>
          </span>
        );
      case 'negative':
        return (
          <span className="badge rounded-pill bg-light-danger text-danger p-2">
            เชิงลบ{' '}
            <i className="fas fa-chevron-circle-down text-danger fa-sm"></i>
          </span>
        );
      default:
        return (
          <span className="badge rounded-pill bg-light p-2">
            ไม่เป็นทั้งบวกและลบ <i class="fas fa-minus-circle fa-sm"></i>
          </span>
        );
    }
  };

  if (sentiment === undefined) {
    return null;
  }

  return (
    <div className="sentiment text-muted">
      แนวโน้ม {sentimentToText(sentiment)} ความแม่นยำในการทำนาย{' '}
      {sentiment.score}{' '}
      <span className="badge badge-pill badge-primary">beta</span>
    </div>
  );
}
