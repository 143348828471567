import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../controls';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function AccountSetting(props) {
  const dispatch = useDispatch();
  const [degreeProgram, setDegreeProgram] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const intl = useIntl();

  useEffect(() => {
    /**
     * Get initial Props
     * Props: Degree Program
     */
    axios.get('/degreeprogram').then(({ data: { data } }) => {
      setDegreeProgram([...data]);
    });
  }, []);

  const initialValues = {
    avatar: user.avatar,
    title: user.title,
    firstName: user.firstName,
    lastName: user.lastName,
    gender: user.gender,
    email: user.email,
    personalId: user.personalId,
    degreeProgram: user.degreeProgram._id,
  };

  const accountSettingSchema = Yup.object().shape({
    avatar: Yup.string(), // TODO: Look after feature upload avatar added
    title: Yup.string().required(
      intl.formatMessage({
        id: 'ACCOUNT.SETTING.VALIDATION.REQUIRED_FIELD',
      })
    ),
    firstName: Yup.string()
      .trim()
      .matches(/[\u0E00-\u0E7F]{1,}$/, { message: 'กรุณาระบุชื่อภาษาไทย' })
      .required(
        intl.formatMessage({
          id: 'ACCOUNT.SETTING.VALIDATION.REQUIRED_FIELD',
        })
      ),
    lastName: Yup.string()
      .trim()
      .matches(/[\u0E00-\u0E7F]{1,}$/, { message: 'กรุณาระบุนามสกุลภาษาไทย' })
      .required(
        intl.formatMessage({
          id: 'ACCOUNT.SETTING.VALIDATION.REQUIRED_FIELD',
        })
      ),
    gender: Yup.string().required(
      intl.formatMessage({
        id: 'ACCOUNT.SETTING.VALIDATION.REQUIRED_FIELD',
      })
    ),
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: 'ACCOUNT.SETTING.VALIDATION.EMAIL_WRONG_FORMAT',
        })
      )
      .required(
        intl.formatMessage({
          id: 'ACCOUNT.SETTING.VALIDATION.REQUIRED_FIELD',
        })
      ),
    personalId: Yup.string().required(
      intl.formatMessage({
        id: 'ACCOUNT.SETTING.VALIDATION.REQUIRED_FIELD',
      })
    ),
    degreeProgram: Yup.string().required(
      intl.formatMessage({
        id: 'ACCOUNT.SETTING.VALIDATION.REQUIRED_FIELD',
      })
    ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: accountSettingSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      axios
        .put('/users', values)
        .then((response) => {
          dispatch({
            type: '[Update User] Action',
            payload: { user: response.data.data },
          });
          toast.success(response.data.message + '🥳');
          setSubmitting(false);
        })
        .catch((error) => {
          toast.error('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง');
          setSubmitting(false);
        });
    },
  });

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({ id: 'ACCOUNT.SETTING.TITLE' }, props.title)}
      >
        <CardHeaderToolbar>
          <Button
            variant="primary"
            disabled={formik.isSubmitting}
            type="submit"
            onClick={formik.handleSubmit}
          >
            {formik.isSubmitting
              ? intl.formatMessage({ id: 'ACCOUNT.SETTING.SUBMITTING' })
              : intl.formatMessage({ id: 'ACCOUNT.SETTING.SUBMIT' })}
          </Button>
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody>
        {/* Start Form */}
        <Form>
          {/* Avatar */}
          <Form.Group as={Row} controlId="formHorizontalAvatar">
            <Form.Label column sm="2" className="text-lg-right text-left">
              {intl.formatMessage({ id: 'USER.AVATAR' }, props.form_avatar)}
            </Form.Label>
            <Col sm={10}>
              <div
                className="image-input image-input-empty image-input-outline"
                style={{
                  backgroundImage: `url(./media/users/blank.png)`,
                }}
              >
                <div className="image-input-wrapper"></div>
                <label
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  style={{ position: 'absolute', right: '-10px', top: '-10px' }}
                >
                  <i className="fa fa-pen icon-sm text-muted"></i>
                  <Form.Control
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    style={{
                      display: 'none',
                      width: 0,
                      height: 0,
                      appearance: 'initial',
                      backgroundColor: 'initial',
                      cursor: 'default',
                      alignItems: 'baseline',
                      color: 'inherit',
                      textAlign: 'start !important',
                      padding: 'initial',
                      border: 'initial',
                    }}
                    className={getInputClasses('avatar')}
                    disabled
                  ></Form.Control>
                </label>
              </div>
            </Col>
          </Form.Group>

          {/* Title */}
          <Form.Group as={Row} controlId="formHorizontalTitle">
            <Form.Label column sm="2" className="text-lg-right text-left">
              {intl.formatMessage(
                { id: 'ACCOUNT.SETTING.FORM_TITLE' },
                props.form_title
              )}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                className={getInputClasses('title')}
                {...formik.getFieldProps('title')}
              >
                <option value="อ.">อาจารย์</option>
                <option value="ดร.">ดร.</option>
                <option value="รศ.">รศ.</option>
                <option value="รศ. ดร.">รศ. ดร.</option>
                <option value="ผศ.">ผศ.</option>
                <option value="ผศ. ดร.">ผศ. ดร.</option>
                <option value="ศ.">ศ.</option>
                <option value="ศ. ดร.">ศ. ดร.</option>
              </Form.Control>
              {formik.touched.title && formik.errors.title && (
                <div className="text-danger">{formik.errors.title}</div>
              )}
            </Col>
          </Form.Group>

          {/* First Name */}
          <Form.Group as={Row} controlId="formHorizontalFirstName">
            <Form.Label column sm={2} className="text-lg-right text-left">
              {intl.formatMessage(
                { id: 'ACCOUNT.SETTING.FORM_FIRSTNAME' },
                props.form_firstName
              )}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder={intl.formatMessage(
                  { id: 'ACCOUNT.SETTING.FORM_FIRSTNAME' },
                  props.form_firstName
                )}
                className={getInputClasses('firstName')}
                {...formik.getFieldProps('firstName')}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className="text-danger">{formik.errors.firstName}</div>
              )}
            </Col>
          </Form.Group>

          {/* Last Name */}
          <Form.Group as={Row} controlId="formHorizontalLastName">
            <Form.Label column sm={2} className="text-lg-right text-left">
              {intl.formatMessage(
                { id: 'ACCOUNT.SETTING.FORM_LASTNAME' },
                props.form_lastName
              )}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder={intl.formatMessage(
                  { id: 'ACCOUNT.SETTING.FORM_LASTNAME' },
                  props.form_lastName
                )}
                className={getInputClasses('lastName')}
                {...formik.getFieldProps('lastName')}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className="text-danger">{formik.errors.lastName}</div>
              )}
            </Col>
          </Form.Group>

          {/* Gender */}
          <Form.Group as={Row} controlId="formHorizontalGender">
            <Form.Label column sm={2} className="text-lg-right text-left">
              {intl.formatMessage(
                { id: 'ACCOUNT.SETTING.FORM_GENDER' },
                props.form_gender
              )}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                className={getInputClasses('gender')}
                {...formik.getFieldProps('gender')}
              >
                <option value="ชาย">
                  {intl.formatMessage(
                    { id: 'USER.GENDER.MALE' },
                    props.form_gender_male
                  )}
                </option>
                <option value="หญิง">
                  {intl.formatMessage(
                    { id: 'USER.GENDER.FEMALE' },
                    props.form_gender_female
                  )}
                </option>
                <option value="ไม่ระบุ">
                  {intl.formatMessage(
                    { id: 'USER.GENDER.NO' },
                    props.form_gender_no
                  )}
                </option>
              </Form.Control>
              {formik.touched.gender && formik.errors.gender && (
                <div className="text-danger">{formik.errors.gender}</div>
              )}
            </Col>
          </Form.Group>

          {/* Email Address */}
          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={2} className="text-lg-right text-left">
              {intl.formatMessage(
                { id: 'ACCOUNT.SETTING.FORM_EMAIL' },
                props.form_email
              )}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="email"
                placeholder={intl.formatMessage(
                  { id: 'ACCOUNT.SETTING.FORM_EMAIL' },
                  props.form_email
                )}
                className={getInputClasses('email')}
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="text-danger">{formik.errors.email}</div>
              )}
            </Col>
          </Form.Group>

          {/* Personal ID */}
          <Form.Group as={Row} controlId="formHorizontalPersonalId">
            <Form.Label column sm={2} className="text-lg-right text-left">
              {intl.formatMessage(
                { id: 'ACCOUNT.SETTING.FORM_PERSONAL_ID' },
                props.form_personalId
              )}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder={intl.formatMessage(
                  { id: 'ACCOUNT.SETTING.FORM_PERSONAL_ID' },
                  props.form_personalId
                )}
                className={getInputClasses('personalId')}
                {...formik.getFieldProps('personalId')}
              />
              {formik.touched.personalId && formik.errors.personalId && (
                <div className="text-danger">{formik.errors.personalId}</div>
              )}
            </Col>
          </Form.Group>

          {/* Degree Program */}
          <Form.Group as={Row} controlId="formHorizontalDegreeProgram">
            <Form.Label column sm={2} className="text-lg-right text-left">
              {intl.formatMessage(
                { id: 'ACCOUNT.SETTING.FORM_DEGREE_PROGRAM' },
                props.form_degree_program
              )}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                placeholder={intl.formatMessage(
                  { id: 'ACCOUNT.SETTING.FORM_DEGREE_PROGRAM' },
                  props.form_degree_program
                )}
                className={getInputClasses('degreeProgram')}
                {...formik.getFieldProps('degreeProgram')}
              >
                {degreeProgram.map((dps) => (
                  <option key={dps._id} value={dps._id}>
                    {dps.name} {dps.major}
                  </option>
                ))}
              </Form.Control>
              {formik.touched.degreeProgram && formik.errors.degreeProgram && (
                <div className="text-danger">{formik.errors.degreeProgram}</div>
              )}
            </Col>
          </Form.Group>
        </Form>
        <ToastContainer />
      </CardBody>
    </Card>
  );
}
