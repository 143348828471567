import { createSlice } from '@reduxjs/toolkit';

const initialDashboardState = {
  diary: {
    actionsLoading: null,
    listLoading: null,
    entities: [],
    totalCount: 0,
    diaryForRead: null,
    reactions: [],
  },
};

export const callTypes = {
  list: 'list',
  action: 'action',
};

export const module = {
  news: 'news',
  diary: 'diary',
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialDashboardState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      const module = action.payload.module;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state[module].listLoading = true;
      } else {
        state[module].actionsLoading = true;
      }
    },
    diariesFetched: (state, action) => {
      state.diary.entities = action.payload.entities;
      state.diary.totalCount = action.payload.totalCount;
      state.diary.listLoading = false;
    },
    diaryFetched: (state, action) => {
      state.diary.actionsLoading = false;
      state.diary.diaryForRead = action.payload.diaryForRead;
      state.diary.error = null;
    },
    reactionsFetched: (state, action) => {
      state.diary.reactions = action.payload.reactions;
      state.diary.actionsLoading = false;
      state.diary.error = null;
    },
    reactionUpdated: (state, action) => {
      // Diary
      const { _id, status, updatedAt } = action.payload;

      // Update Action
      state.diary.actionsLoading = false;
      state.diary.error = null;

      // Update diaryForRead
      state.diary.diaryForRead = {
        ...state.diary.diaryForRead,
        status,
        updatedAt,
      };

      // Update entities
      state.diary.entities = state.diary.entities.filter(
        (el) => el._id !== _id
      );
    },
  },
});
