import React, { createContext, useContext, useState, useCallback } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './DashboardUIHelper';

const DashboardUIContext = createContext();

export function useDashboardUIContext() {
  return useContext(DashboardUIContext);
}

export const DashboardUIConsumer = DashboardUIContext.Consumer;

export function DashboardUIProvider({ dashboardUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);

  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initDiary = {
    status: {
      isRead: false,
      reaction: null,
    },
    _id: null,
    title: null,
    story: null,
    summarize: null,
    owner: {
      avatar: 'avatar.png',
      _id: null,
      title: null,
      firstName: null,
      lastName: null,
    },
    createdAt: null,
    updatedAt: null,
  };

  const value = {
    queryParams,
    setQueryParams,
    initDiary,
    openRecentDiaryDialog: dashboardUIEvents.openRecentDiaryDialog,
  };

  return (
    <DashboardUIContext.Provider value={value}>
      {children}
    </DashboardUIContext.Provider>
  );
}
