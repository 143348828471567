import { createSlice } from "@reduxjs/toolkit";

const initialDiariesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  diaryForRead: undefined,
  reactions: undefined,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const diarySlice = createSlice({
  name: "diaries",
  initialState: initialDiariesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    reactionFetched: (state, action) => {
      state.reactions = action.payload.reactions;
      state.actionsLoading = false;
      state.error = null;
    },
    // getCustomerById
    diaryFetched: (state, action) => {
      state.actionsLoading = false;
      state.diaryForRead = action.payload.diaryForRead;
      state.error = null;
    },
    // findCustomers
    diariesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // updateCustomer
    reactionUpdated: (state, action) => {
      const { _id, status, updatedAt } = action.payload;

      state.error = null;
      state.actionsLoading = false;

      // Update diaryForRead
      state.diaryForRead = {
        ...state.diaryForRead,
        status,
        updatedAt
      }
      // Update entities
      state.entities = state.entities.map((entity) => {
        if (entity._id === _id) {
          return {
            ...entity,
            status,
            updatedAt
          }
        }
        return entity;
      })
    },

    // customersUpdateState
    diarysStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
